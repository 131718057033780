import React, { useState } from "react"
import { Device, CloudPlan } from "../../models"
import { AppState, UserState } from "../../state";

import { Typography, Input } from "antd"
import { LoadingOutlined } from '@ant-design/icons';
import { useSelector } from "react-redux";
import { CloudCheckout } from "./cloud-checkout";

import { GetDeviceTypeImageForDetails } from "../../utils/device/device.helpers"
import { createLog } from "../../utils/extras/log.helpers";

const { Title, Text } = Typography

interface CloudDeviceInfoProps {
  device: Device;
  selectedPlan?: CloudPlan;
}

export const CloudDeviceInfo: React.FC<CloudDeviceInfoProps> = (props) => {
  const { device, selectedPlan } = props
  const { user } = useSelector<AppState, UserState>((state) => state.user)

  // LOCAL STATES
  const [promoInputSpin, setPromoInputSpin] = useState(false);
  const [showDiscount, setShowDiscount] = useState(true);
  const [discount, setDiscount] = useState<number>(0.40);
  const [discountMsg, setDiscountMsg] = useState('');
  const [promoMsgClass, setPromoMsgClass] = useState('');


  // HANDLE CHECK PROMO CODE
  const handleCheckPromoCode = (event: any) => {
    setPromoInputSpin(true)
    createLog(event.target.value)
    setTimeout(() => {
      setShowDiscount(true)
      setDiscountMsg('10% discount!')
      setPromoMsgClass('success')
      setPromoInputSpin(false)
    }, 1000)
  }

  return (
    <>
      <div className="cloud-device-info-section">
        {/* SECTION TITLE */}
        <Title level={2} style={{ marginBottom: "2rem" }}>
          Details
        </Title>
        {/* DEVICE IMAGE AND NAME */}
        <div style={{ display: "flex" }}>
          <div>
            <img
              width="200px"
              src={GetDeviceTypeImageForDetails(device.deviceType)}
              alt="device img"
            />
          </div>
          <div>
            <Text strong style={{ textAlign: "left", fontSize: '16px' }}>
              {device.name}
            </Text>
          </div>
        </div>
        {/* CLOUD PLAN NAME AND DESCRIPTION */}
        <div style={{marginTop: '2rem'}}>
          <Text strong style={{fontSize: '16px', color: 'black'}}>
            {selectedPlan ? selectedPlan.name : 'Select a Plan'}
          </Text>
          <br/>
          <p className="cloud-device-info-section-plan-description">
            {selectedPlan ? selectedPlan.longDescription : 'No plan selected'}
          </p>
        </div>
        {/* CLOUD PLAN RENEWAL AND PAYMENT */}
        <div style={{ marginTop: '2rem', display: "flex", justifyContent: 'space-between' }}>
          <div>
            <Text strong className="cloud-device-info-section-plan-title">
              Renew
            </Text>
            <br/>
            <Text className="cloud-device-info-section-plan-description">
              {selectedPlan ? 'Auto renew' : '-'}
            </Text>
          </div>
          <div>
            <Text strong className="cloud-device-info-section-plan-title">
              Payment method
            </Text>
            <br/>
            <Text className="cloud-device-info-section-plan-description">
              No payment method
            </Text>
          </div>
        </div>
        {/* CLOUD PLAN PRICE AND CAPACITY */}
        <div style={{ marginTop: '2rem', display: "flex", justifyContent: 'space-between' }}>
          <div>
            <Text strong className="cloud-device-info-section-plan-title">
              Plan price
            </Text>
            <br/>
            <Text className="cloud-device-info-section-plan-description">
              {selectedPlan ? `$${selectedPlan.price}` : '-'}
            </Text>
          </div>
          <div>
            <Text strong className="cloud-device-info-section-plan-title">Capacity</Text>
            <br/>
            <Text className="cloud-device-info-section-plan-description">
              {selectedPlan ? `${selectedPlan.capacity}GB` : '-'}
            </Text>
          </div>
        </div>
        {/* PROMO CODE INPUT */}
        <div style={{ marginTop: '2rem' }}>
          <Input
            size='large'
            className="cloud-device-info-section-promo-code-input"
            placeholder='Promo code'
            suffix={promoInputSpin && <LoadingOutlined />}
            onPressEnter={handleCheckPromoCode}
          />
          {showDiscount && 
            <Text strong className={`cloud-device-info-section-${promoMsgClass}-promo-message`}>
              {discountMsg}
            </Text>}
        </div>
        {/* CHECKOUT */}
        <CloudCheckout selectedPlan={selectedPlan} discount={discount} />
      </div>
    </>
  )
}
