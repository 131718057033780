import React, { useState } from "react"
import { Card, List, Typography, Radio } from "antd"
import { CloudPlan } from "../../models"
import { ListGridType } from "antd/lib/list";

const { Title, Text } = Typography

const gridColumnSettings: ListGridType = {
  gutter: 48,
  xs: 1,
  sm: 2,
  md: 2,
  column: 3,
  lg: 2,
  xl: 3,
  xxl: 3,
};

interface CloudPlanSectionProps {
  setCloudPlan: React.Dispatch<React.SetStateAction<CloudPlan | undefined>>;
}

export const CloudPlanSection: React.FC<CloudPlanSectionProps> = (props) => {
  const { setCloudPlan } = props;
  let plans: CloudPlan[] = [
    {
      name: "Basic Plan",
      description: "1GB or up to 7 days of video history",
      longDescription: "Recordings are generated by motion detection. Either 1GB or up to 7-days of recordings are held in the Cloud. After 1GB or up to 7-days of recording, your data is permanently overwritten.",
      price: 4,
      capacity: 1,
      daysHeld: 7,
      isSelected: false,
    },
    {
      name: "Intermediate Plan",
      description: "2GB or up to 14 days of video history",
      longDescription: "Recordings are generated by motion detection. Either 2GB or up to 14-days of recordings are held in the Cloud. After 2GB or up to 14-days of recording, your data is permanently overwritten.",
      price: 7,
      capacity: 2,
      daysHeld: 14,
      isSelected: false,
    },
    {
      name: "Pro Plan",
      description: "4GB or up to 30 days of video history",
      longDescription: "Recordings are generated by motion detection. Either 4GB or up to 30-days of recordings are held in the Cloud. After 4GB or up to 30-days of recording, your data is permanently overwritten.",
      price: 10,
      capacity: 4,
      daysHeld: 30,
      isSelected: false,
    },
  ]

  const [planList, setPlanList] = useState<CloudPlan[]>(plans);

  const handleSelectPlan = (event: any) => {
    // console.log(event.target.value)
    // console.log(typeof(event.target.value))
    const value = event.target.value

    if (value === '4') {
      plans[0].isSelected = true
      plans[1].isSelected = false
      plans[2].isSelected = false
      setCloudPlan(plans[0])
    } else if (value === '7') {
      plans[0].isSelected = false
      plans[1].isSelected = true
      plans[2].isSelected = false
      setCloudPlan(plans[1])
    } else {
      plans[0].isSelected = false
      plans[1].isSelected = false
      plans[2].isSelected = true
      setCloudPlan(plans[2])
    }

    // console.log(JSON.stringify(plans, null, 2))
    setPlanList(plans)
    // console.log(JSON.stringify(planList, null, 2))
  }

  return (
    <List
      // header={<Title>Select a plan</Title>}
      style={{ 
        marginTop: '12px',
        paddingRight: "24px", 
        paddingLeft: "24px", 
        width: '100%' 
      }}
      grid={gridColumnSettings}
      dataSource={planList}
      renderItem={(plan) => (
        <List.Item>
          <Card 
            className="plan-card"
            bordered={true}
            // title={plan.name}
          >
            <div className="plan-card-details">
              <Title level={4}>{plan.name}</Title>
              <div style={{marginTop: '-1rem'}}>
                <Text style={{fontSize: '12px', color: 'darkgrey', textAlign: 'justify'}}>
                  {plan.description}
                </Text>
              </div>
              <div style={{marginTop: '2rem'}}>
                <Title level={3}>{`Fixed $${plan.price}/month`}</Title>
              </div>
              <div style={{textAlign: 'center'}}>
                <Radio
                  style={{padding: '12px'}}
                  value={plan.price} 
                  checked={plan.isSelected} 
                  onClick={handleSelectPlan} 
                />
              </div>
            </div>
          </Card>
        </List.Item>
      )}
    />
  )
}
