import React, { useState } from "react"
import { RouteComponentProps } from "@reach/router"
import { FormattedMessage } from "../shared"
import { Device, CloudPlan } from "../../models"

import { PageHeader, Typography, Row, Col } from "antd"
import { CloudPlanSection } from "./cloud-plans-section"
import { CloudDeviceInfo } from "./cloud-device-info"

const { Title } = Typography

interface CloudPlanContainerProps extends RouteComponentProps {
  locationState: any
}

export const CloudPlanContainer: React.FC<CloudPlanContainerProps> = (props) => {
  const { locationState } = props
  const selectedDevice: Device = typeof locationState !== "undefined" ? locationState.device : undefined
  const [selectedPlan, setSelectedPlan] = useState<CloudPlan>();

  return (
      <Row justify="center">
        <Col 
          xl={{ span: 18 }}
          lg={{ span: 16 }}
          md={{ span: 14 }}
          sm={{ span: 12 }}
          xs={{ span: 0 }}
        >
        <PageHeader
          title={
            <Title level={2}>
              <FormattedMessage id="app.devices.cloud-recording-section.title" />
            </Title>
          }
        />
          <CloudPlanSection setCloudPlan={setSelectedPlan} />
        </Col>
        <Col
          xl={{ span: 6 }}
          lg={{ span: 8 }}
          md={{ span: 10 }}
          sm={{ span: 12 }}
          xs={{ span: 0 }}
        >
          <CloudDeviceInfo device={selectedDevice} selectedPlan={selectedPlan} />
        </Col>
      </Row>
  )
}
