import React from "react"
import { useSelector } from "react-redux"
import { UserState, AppState } from "../state"
import { RouteComponentProps } from "@reach/router"

import LandingPage from "./landing"
import NotFoundPage from "./404"
import { CloudPlanContainer } from "../components/cloud/cloud-container"
import { createLog } from "../utils/extras/log.helpers"


const CloudPage: React.FC = (props: RouteComponentProps) => {
  // createLog(JSON.stringify(props.location, null, 2))
  const { location } = props;
  const { user } = useSelector<AppState, UserState>((state) => state.user)

  createLog(location)

  return (
    <>
      {!user.Username ? (
        <LandingPage />
      ) : location?.state ? (
        <CloudPlanContainer 
          locationState={location.state} 
        />
      ) : (
        // <NotFoundPage content="No device selected" />
        <NotFoundPage content="Page Not Found" /> // For now
      )}
    </>
  )
}

export default CloudPage
