import React from 'react';
import { CloudPlan } from '../../models';
import { useSelector } from 'react-redux';
import { AppState, UserState } from '../../state';

import { Typography, Button } from 'antd';

const {Text, Title} = Typography;

interface CloudCheckoutProps {
  discount: number;
  selectedPlan?: CloudPlan;
}

export const CloudCheckout: React.FC<CloudCheckoutProps> = (props) => {
  const { selectedPlan, discount } = props;
  const { user } = useSelector<AppState, UserState>((state) => state.user)

  return (
    <>
      {/* SUBTOTAL AND PRICE */}
      <div style={{ marginTop: '2rem', display: "flex", justifyContent: 'space-between' }}>
        <div>
          <Text strong style={{ textAlign: "left", fontSize: '16px' }}>
            {selectedPlan ? 'Subtotal(1 item)' : 'Subtotal(0 items)'}
          </Text>
        </div>
        <div>
          <Text strong style={{ textAlign: "right", fontSize: '16px', color: '#a00404c9' }}>
            {selectedPlan ? `$${selectedPlan.price}` : '$0'}
          </Text>
        </div>
      </div>
      {/* DISCOUNT AND TAXES */}
      {discount !== 0 ? (
        <div style={{textAlign: 'right', fontSize: '12px'}}>
          <Text>
            {`Promo code -$${discount.toFixed(2)}`}
          </Text> 
          <br/>
          <Text>
            Taxes(will be calculated after checkout)
          </Text>
        </div>
      ) : null}
      {/* CHECKOUT BUTTON */}
      <div style={{ marginTop: '2rem', textAlign: 'center' }}>
        <Button
          size='large'
          className="cloud-checkout-button"
        >Proceed to checkout</Button>
      </div>
    </>
  )
}